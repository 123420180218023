import React from 'react'
import logo from '../../assets/housinng.bt logo and color palettes/housinng.bt logo and color palettes/Housing_Bt logo/SVG/Housinng_bt Logo.svg'

type Props = {
  loader?: boolean | string
  noData?: boolean | string
  style?: object
}

function LoaderBox({ loader, noData, style }: Props) {
  return (
    <>
      {loader && (
        <div className="loading-content" style={style}>
          <img src={logo} alt="Loading" />
          <h1>{loader === true ? 'Loading..' : loader}</h1>
        </div>
      )}
      {!loader && noData && (
        <div className="loading-content" style={style}>
          <img src={logo} alt="No Data found" />
          <h1>{noData === true ? 'No Data found..' : noData}</h1>
        </div>
      )}
    </>
  )
}

export default LoaderBox
